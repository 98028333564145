import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import GroupTravelTheme from "../components/GroupTravelTheme";
import BannerGroupTravel from "../components/BannerGroupTravel";
import PlanningTrip from "../components/PlanningTrip";
import GroupContact from "../components/GroupContact";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import { fetchData } from "../services/api";

const GroupTravel: React.FC = () => {
  const [planningTripData, setPlanningTripData] = useState<{
    headingplanning: string;
    paragraph: string;
  }>({
    headingplanning: "",
    paragraph: "",
  });

  const [metaData, setMetaData] = useState({
    pageTitle:
      "Plan Your Perfect Group Trip | Aurora's Top Group Travel Agency - Travel N Relax",
    metaKeyword: "",
    metaDescription: "",
    metaTitle:
      "Aurora’s Top-Rated Family Travel Agency | Stress-Free Vacations | Travel N’ Relax",
  });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlanningTripData = async () => {
      try {
        const response = await fetchData<{
          whereTo: { headingplanning: string; paragraph: string }[];
        }>({
          url: "pages/get",
          data: { pageId: 3 },
        });

        const whereToData = response?.data?.whereTo?.[0];
        if (whereToData) {
          setPlanningTripData(whereToData);
        } else {
          throw new Error("No whereTo data found.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load Planning Trip data.");
      }
    };

    fetchPlanningTripData();
  }, []);

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await fetchData<{
          meta_keyword: string;
          meta_description: string;
        }>({
          url: "pages/get",
          data: { pageId: 3 },
        });

        if (response?.data) {
          const { meta_keyword, meta_description } = response.data;

          setMetaData((prev) => ({
            ...prev,
            metaKeyword: meta_keyword,
            metaDescription: meta_description,
          }));
        } else {
          throw new Error("Metadata not found for the page.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load metadata.");
      }
    };

    fetchMetaData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{metaData.pageTitle}</title>
        <meta name="title" content={metaData.metaTitle} />
        <meta name="keywords" content={metaData.metaKeyword} />
        <meta name="description" content={metaData.metaDescription} />
        <link rel="canonical" href="https://travelnrelax.com/group-travel/" />
      </Helmet>

      <Navbar heading="DENVER’S TOP RATED FAMILY TRAVEL AGENCY" subHeading="" />
      <GroupTravelTheme />
      <BannerGroupTravel />
      <GroupContact />

      {error ? (
        <p className="text-red-600">{error}</p>
      ) : (
        <PlanningTrip
          headingplanning={
            planningTripData.headingplanning || "Default Heading"
          }
          paragraph={planningTripData.paragraph}
        />
      )}

      <Clients />
      <Footer />
    </div>
  );
};

export default GroupTravel;
