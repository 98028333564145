import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import Aboutus from "../components/Aboutus";
import TravelDirector from "../components/TravelDirector";
import TravelAdvisors from "../components/TravelAdvisors";
import OurPhilosophy from "../components/OurPhilosophy";
import PrincipleCard from "../components/PrincipleCard";
import HeroSection from "../components/HeroSection";
import ServiceOverview from "../components/ServiceOverview";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import RobDetail from "../components/RobDetail";
import RobDescription from "../components/RobDescription";
import AdvisorCertification from "../components/AdvisorCertification";
import AdvisorTestimonial from "../components/AdvisorTestimonial";

const AdvisorDetail: React.FC = () => {
  return (
    <div>
      <Helmet>
        <meta
          name="tittle"
          content="Rob Hale | ASTA Verified Travel Advisor & Certified Travel Counselor"
        />
        <meta
          name="description"
          content="Rob Hale is an ASTA Verified Travel Advisor and Certified Travel Counselor specializing in customized vacations, cruises, destination weddings, and honeymoons. Plan your dream trip today with Rob's expert travel advice."
        />
        <meta
          name="keywords"
          content="Rob Hale, ASTA Travel Advisor, Certified Travel Counselor, Certified Travel Associate, Disney Travel Specialist, Hawaii Destination Specialist, Alaska travel expert, Mexico travel expert, Sandals resort specialist, destination weddings, honeymoon travel, cruise vacation planner, luxury travel planning, ski travel specialist, golf travel vacations, travel consultant"
        />
      </Helmet>

      <Navbar />
      <RobDescription />
      <AdvisorCertification />
      <AdvisorTestimonial />
      <PlanningTrip
        headingplanning="Where to next?"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />
      <Footer />
    </div>
  );
};

export default AdvisorDetail;
