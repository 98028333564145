import React from "react";
import ReactDOM from "react-dom"; // ✅ use react-dom, not react-dom/client
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.hydrate(
  <App />,
  document.getElementById("root")
);

reportWebVitals();
